import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { RouterModule, Route, Routes } from '@angular/router';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CafeSignUpFormComponent } from './cafesugnup-form/cafesugnup-form.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

const appRoutes: Routes = [
  {
    path: 'referral/:refCode',
    component: SignupFormComponent
  },
  {
    path: '',
    component: SignupFormComponent
  },
  { path: 'cafe', component: CafeSignUpFormComponent },
  { path: 'cafe/referral/:refCode', component: CafeSignUpFormComponent }
];

@NgModule({
  declarations: [AppComponent, SignupFormComponent, CafeSignUpFormComponent],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabled' }),
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
