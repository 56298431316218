import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'exspresso-cafe-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {
  signUpForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    acceptsTerms: new FormControl(false, Validators.requiredTrue),
    referralCode: new FormControl('')
  });

  done = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('refCode')) {
      this.signUpForm.patchValue({
        referralCode: this.route.snapshot.paramMap.get('refCode')
      });
      console.log(this.route.snapshot.paramMap.get('refCode'));
    }
  }

  onSubmit() {
    if (!this.signUpForm.valid) {
      return;
    }

    const formValues = this.signUpForm.value;
    console.log(formValues.firstName);
    const httpsOptions = new HttpHeaders({
      'content-type': 'application/json',
      Authorization:
        'Bearer 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'
    });

    this.http
      .put(
        'https://api.sendgrid.com/v3/marketing/contacts',
        {
          list_ids: ['5ba643e2-fe10-4ea2-899f-1492272072c5'],
          contacts: [
            {
              email: formValues.email,
              first_name: formValues.firstName,
              last_name: formValues.lastName,
              custom_fields: {}
            }
          ]
        },
        { headers: httpsOptions }
      )
      .subscribe(
        data => {
          console.log(data);
          this.done = true
        },
        err => {
          console.error(err);
        }
      );
  }
}
